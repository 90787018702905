.resetPwd {
    width: 100%;
    height: 100%;
    background-color: $yellow;
    position: relative;
    overflow: hidden;

    .boxReset {
        margin: auto;
        padding: 15px 45px 15px 45px;
        position: relative;
        top: 0px;
        z-index: 1;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
        top: 100px;
        width: 90%;
        max-width: 1000px;
        background-color: white;
        overflow: hidden;
    }

    
    .resetPwd-form {
        margin-top : 20px;
        
        input {
            font-family: "Roboto", sans-serif;
            outline: 0;
            background: #f2f2f2;
            width: 100%;
            height:100px;
            border: 0;
            margin: 0 0 15px;
            padding: 15px;
            box-sizing: border-box;
            font-size: 14px;
            display: inline-block;
            margin-right:-60px;
            margin-bottom:5px;
            font-size: 26px;
        }
        button {
            font-family: "Roboto", sans-serif;
            text-transform: uppercase;
            outline: 0;
            background: #FF7F00;
            width: 100%;
            border: 0;
            padding: 15px;
            color: #FFFFFF;
            font-size: 14px;
            -webkit-transition: all 0.3 ease;
            transition: all 0.3 ease;
            cursor: pointer;
            height:80px;
            font-weight: bold;
            font-size:26px;
        }
       
    }
}

.pReset {
    margin-bottom:15px;
    color:#555
}

button:disabled{
    opacity: 0.5;
    cursor: not-allowed !important;
}

.containerSearchEye{
    display: inline-block;
}

.searchEye {
    font-size: 32px;
    color:#555;
    vertical-align:middle;
    margin-bottom:10px;
}

.errorDiv {
    height: 40px;
}

.errorDiv > span {
    color: rgb(219, 0, 0);
    font-size: 26px;
}

.redBorder {
    border: 2px solid rgb(211, 0, 0) !important;
}

.greenBorder {
    border: 2px solid green !important;
}

.logo {
    width:100%;
    margin-left:-5px;
    max-width:550px
}

p {
    font-size:26px
}

.helpers {
    font-size: 26px;
    text-align: left;
    margin-bottom:30px;
}

.md-title {
    font-size: 30px;
    font-weight: bold;
}

.md-confirm-button {
    font-size: 26px;
    border: rgb(63,81,181) 1px solid;
}